const t = {
    icon: 'fa-handshake-simple-slash',
    menus: {
        main: {section: 'leads', priority: 30},
    },
    new: {
        attributes: [
            'tenant', 'email', 'lastName', 'firstName','phone','url'
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '!$email!', '$lastName','$firstName','$phone','$url'
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'tenant', 'email', 'lastName', 'firstName', 'status','phone','url'
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '!$email!', '$lastName','$firstName','$status','$phone','$url'
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        toolbar:true,
        tabs:[
            {name:'infos'},
            {name:'leads'}
        ],
    },
    list: {
        props: {
            search: false,
            searchSwitch: false,
        },
        icon: 'fa-user-tie',
        columns: [
            {id: 'tenantName', label: 'column_tenant_label'},
            {id: 'code', label: 'column_code_label'},
            {id: 'firstName', label: 'column_firstname_label'},
            {id: 'lastName', label: 'column_lastname_label'},
            {id: 'email', label: 'column_email_label'},
            {id: 'phone', label: 'column_phone_label'},
            {id: 'url', label: 'column_url_label'},
            {id: 'createdAt', label: 'column_createdat_label', format: (v: any) => v ? new Date(parseInt(v)).toLocaleString() : 'undefined'},
            {id: 'status', label: 'column_status_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
    types: {
        lead: {
                 list: {
                    moveable:false,
                     columns:[
                         {id: 'id', label: 'column_id_label'},
                         {id: 'firstName', label: 'column_firstname_label'},
                         {id: 'lastName', label: 'column_lastname_label'},
                         {id: 'email', label: 'column_email_label'},
                         {id: 'phone', label: 'column_phone_label'},
                         {id: 'code', label: 'column_code_label'},
                         {id: 'createdAt', label: 'column_createdat_label', format: (v: any) => v ? new Date(parseInt(v)).toLocaleString() : 'undefined'},
                         {id: 'updatedAt', label: 'column_updatedat_label', format: (v: any) => v ? new Date(parseInt(v)).toLocaleString() : 'undefined'},
                         {id: 'status', label: 'column_status_label'},
                     ],
                     actions: ['display']
                 }
             }
     }
};

export default t;