const fields = {
    employeePeriods:{type:'querySelect',kind:'eperiods'},
    attachments: {type: 'textarea', kind: 'attachments'},
    baseAccount: {type: 'amount', kind: 'baseAccount',label:'field_baseaccount_label'},
    bodyFile: {type: 'file', kind: 'bodyFile'},
    bodyTextFile: {type: 'file', kind: 'bodyTextFile'},
    broker: {type:'querySelect', kind: 'broker', convert: (item: any) => ({value: item.id, label: item.lastName ? `${item.lastName} ${item.firstName}` : item.email})},
    commercialFee: {type: 'amount', kind: 'commercialFee',label:'field_commercialfee_label'},
    contract: {type: 'querySelect', kind: 'contract', convert: (item: any) => ({value: item.id, label: `${item.customerName} ${item.dateStart} `  })},
    craUrl: {type: 'text', kind: 'craUrl',label:'field_craurl_label'},
    customer: {type: 'querySelect', kind: 'customer'},
    matricule: { type:'text', kind:'matricule', label: 'field_matricule_label' },
    date: {type: 'number', kind: 'date', label:'field_date_label'},
    dateStart: {type: 'number', kind: 'dateStart', label:'field_datestart_label'},
    dateEnd: {type: 'number', kind: 'dateEnd', label:'field_dateend_label'},
    employerContributions: {type: 'amount', kind: 'employerContributions',label:'field_employer_contributions_label'},
    employeeContributions: {type: 'amount', kind: 'employeeContributions',label:'field_employee_contributions_label'},
    employee: {type: 'querySelect', kind: 'employee',label:'field_employee_label'},
    expenses :{type: 'text', kind: 'expenses',label:'field_expenses_label'},
    externalId: {type:'text', kind:'externalId' ,label:'field_externalid_label'},
    incomeTaxes: {type: 'amount', kind: 'incomeTaxes',label:'field_label'},
    internalFees: {type: 'amount', kind: 'internalFees',label:'field_internalfees_label'},
    locked: {type: 'checkbox', kind: 'locked',label:'field_locked_label'},
    mooncardId: {type:'text', kind:'mooncardId' ,label:'field_mooncardid_label'},
    mainFile: {type: 'file', kind:'mainFile', label:'field_main_file'},
    avatarImage: {type: 'file', kind:'avatarImage', label:'field_main_file'},
    notificationType: {type: 'typeSelect', kind: 'type', values: [
            {value: 'email', label: 'type_email_label'},
            {value: 'sms', label: 'type_sms_label'},
            {value: 'push', label: 'type_push_label'},
        ]},
    name: {type: 'text', kind: 'name'},
    organization: {type: 'querySelect', kind: 'organization'},
    owner: {type: 'querySelect', kind: 'owner', query: 'FIND_USERS', dataKey: 'findUsers', convert: (item: any) => ({value: item.id, label: item.lastName ? `${item.lastName} ${item.firstName}` : item.email})},
    ownerEmail: {type: 'text', kind: 'ownerEmail',label:'field_owneremail_label'},
    ownerName: {type: 'text', kind: 'ownerName',label:'field_ownername_label'},
    ownerFirstName: {type: 'text', kind: 'ownerFirstName',label:'field_ownerfirstname_label'},
    periodPercent: {type: 'number', kind: 'periodPercent',label:'field_period_percent_label'},
    periodTurnover: {type: 'number', kind: 'periodTurnover',label:'field_period_turnover_label'},
    periodExpense: {type: 'amount', kind: 'periodExpense',label:'field_period_expense_label'},
    periodChargeProvided: {type: 'amount', kind: 'field_periodChargeProvided',label:'period_chargeprovided_label'},
    project: {type:'querySelect', kind: 'project'},
    peeAmount: {type: 'amount', kind: 'peeAmount',label:'field_peeamount_label'},
    perAmount: {type: 'amount', kind: 'perAmount',label:'field_peramount_label'},
    payslipUrl: {type: 'text', kind: 'payslipUrl',label:'field_payslipurl_label'},
    rate: {type: 'price', kind:'rate'},
    recipient: {type: 'text', kind: 'recipient'},
    report: {type: 'textarea', kind: 'report'},
    result: {type: 'textarea', kind: 'result'},
    role: {type: 'roleSelect', kind: 'role', values: [
            {value: 'user', label: 'role_user_label'},
            {value: 'admin', label: 'role_admin_label'},
        ]},
    salary: {type: 'amount', kind: 'salary',label:'field_salary_label'},
    sender: {type: 'text', kind: 'sender'},
    taxedSalary: {type: 'amount', kind: 'taxedSalary',label:'field_taxedsalary_label'},
    template: {type: 'text', kind: 'template'},
    tenant: {type: 'querySelect', kind: 'tenant'},
    user: {type: 'querySelect', kind: 'user', convert: (item: any) => ({value: item.id, label: item.lastName ? `${item.lastName} ${item.firstName}` : item.email})},
    values: {type: 'textarea', kind: 'values'},
    variables: {type: 'textarea', kind: 'variables'},
    workedDay: {type: 'number', kind: 'workedDay',label:'field_workedday_label'},

};

export default fields;