const t = {
    icon: 'fa-file',
    menus: {
        main: {section: 'employees', priority: 1},
    },
    new: {
        attributes: [
             'tenant','employee', 'contract','project','dateStart','dateEnd','workedDay',
             'periodPercent','periodTurnover','internalFees','periodExpense',
             'periodChargeProvided','baseAccount','taxedSalary','employerContributions',
             'employeeContributions','salary','peeAmount','perAmount','incomeTaxes',
             'locked','commercialFee','craUrl','payslipUrl','expenses','externalId',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant','$employee','$contract!', '$project','$dateStart','$dateEnd','$workedDay',
                    '$periodPercent','$periodTurnover','$internalFees','$periodExpense',
                    '$periodChargeProvided','$baseAccount','$taxedSalary','$employerContributions',
                    '$employeeContributions','$salary','$peeAmount','$perAmount','$incomeTaxes',
                    '$locked','$commercialFee','$craUrl','$payslipUrl','$expenses','$externalId',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'tenant','employee', 'contract','project','dateStart','dateEnd','workedDay',
            'periodPercent','periodTurnover','internalFees','periodExpense',
            'periodChargeProvided','baseAccount','taxedSalary','employerContributions',
            'employeeContributions','salary','peeAmount','perAmount','incomeTaxes',
            'locked','commercialFee','craUrl','payslipUrl','expenses','externalId',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant:readonly','$employee:readonly','$contract:readonly!', '$project:readonly','$dateStart:readonly','$dateEnd:readonly',

                ]],
                ['block', [
                    '$workedDay',
                    '$periodPercent','$periodTurnover','$internalFees','$periodExpense',
                    '$periodChargeProvided','$baseAccount','$taxedSalary','$employerContributions',
                    '$employeeContributions','$salary','$peeAmount','$perAmount','$incomeTaxes',
                    '$locked','$commercialFee','$craUrl','$payslipUrl','$expenses','$externalId',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
           'customerName','project','tenant','dateStart','dateEnd','locked','payslipUrl','craUrl'
        ],
    },
    list: {
        toolbar:true,
        props: {
            search: false,
            searchSwitch: false,
        },
        icon: 'fa-chart-column',
        columns: [
            {id: 'id', label: 'column_id_label'},
            {id: 'tenantName', label: 'column_tenant_label'},
            {id: 'customerName', label: 'column_customer_label'},
            {id: 'contract', label: 'column_contract_label'},
            {id: 'projectName', label: 'column_project_label'},
            {id: 'workedDay', label: 'column_workedday_label'},
            {id: 'locked', label: 'locked ?'},
            {id: 'externalId', label: 'column_externalid_label'},
            {id: 'dateStart', label: 'column_datestart_label',format: (v: any) => v ? new Date(parseInt(v)*1000).toLocaleString('FR-fr',{weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',}) : 'undefined'},
            {id: 'dateEnd', label: 'column_dateend_label',format: (v: any) => v ? new Date(parseInt(v)*1000).toLocaleString() : 'undefined'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;