const t = {
    icon: 'fa-file',
    menus: {
        main: {section: 'employees', priority: 50},
    },
    new: {
        attributes: [
           'employee', 'tenant', 'externalId','date', 'mainFile'
        ],
        content: [
            ['row', [
                ['block', [
                    '!$tenant!', '$employee!','$externalId!','$date!', '$mainFile!'
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'employee', 'tenant', 'externalId', 'mainFile'
        ],
        content: [
            ['row', [
                ['block', [
                    '!$tenant!', '$employee!','$externalId!', '$mainFile!'
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id','code', 'employeeFirstName','tenantName', 'externalId', ['mainFile']
        ],
    },
    list: {
        props: {
            search: false,
            searchSwitch: false,
        },
        icon: 'fa-toolbox',
        columns: [
            {id: 'tenantName', label: 'column_tenant_label'},
            {id: 'employeeFirstName', label: 'column_employeename_label'},
            {id: 'code', label: 'column_code_label'},
            {id: 'mainFile', label: 'column_mainFile_label'},
            {id: 'externalId', label: 'column_externalid_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;