const t = {
    icon: 'fa-user-tie',
    menus: {
        main: {section: 'settings', priority: 45},
    },
    new: {
        attributes: [
            'code', 'name',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$code!', '$name!',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'code', 'name',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$code!', '$name!',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id', 'code', 'name', 'url'
        ],
    },
    list: {
        props: {
            search: false,
            searchSwitch: false,
        },
        columns: [
            {id: 'code', label: 'column_code_label', flex: 0, width: 150} as any,
            {id: 'name', label: 'column_name_label'} as any,
            {id: 'url', label: 'column_name_label'} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'go_public_page', 'delete']
    },
};

export default t;