const t = {
    icon: 'fa-building',
    menus: {
        main: {section: 'customers', priority: 20},
    },
    new: {
        attributes: [
            'name', 'tenant',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '!$name!',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'name',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant:readonly!', '$code:readonly!', '!$name!',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        toolbar: true,
        properties: [
            'id', 'code', 'name',
        ],
    },
    list: {
        props: {
            search: false,
            searchSwitch: false,
        },
        icon: 'fa-users',
        columns: [
            {id: 'code', label: 'column_code_label'},
            {id: 'name', label: 'column_name_label'},
            {id: 'externalId', label: 'column_externalid_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;