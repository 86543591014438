const t = {
    icon: 'fa-building',
    menus: {
        main: {section: 'settings', priority: 20},
    },
    new: {
        attributes: [
            'name', 'locale', 'zipCode', 'complement', 'state', 'street',
            'city',
            'country',
            'logoImage', 'logoAltImage', 'mainImage',
            'websiteUrl', 'twitterUrl', 'facebookUrl', 'instagramUrl', 'linkedinUrl', 'youtubeUrl', 'pinterestUrl',
            'tumblrUrl', 'flickrUrl', 'redditUrl', 'tiktokUrl', 'vimeoUrl', 'snapchatUrl',
            'backgroundVideoUrl', 'bannerVideoUrl', 'banner2VideoUrl', 'mainVideoUrl',
            'faviconImage', 'appleTouchImage',
            'overline', 'fullName', 'shortName', 'legalName', 'domain', 'mainTheme',
            'owner', 'tenant',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$name!', '$owner!', '$tenant!'
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'name', 'locale', 'zipCode', 'complement', 'state', 'street',
            'city',
            'country',
            'logoImage', 'logoAltImage', 'mainImage', 'bannerImage',
            'websiteUrl', 'twitterUrl', 'facebookUrl', 'instagramUrl', 'linkedinUrl', 'youtubeUrl', 'pinterestUrl',
            'tumblrUrl', 'flickrUrl', 'redditUrl', 'tiktokUrl', 'vimeoUrl', 'snapchatUrl',
            'backgroundVideoUrl', 'bannerVideoUrl', 'banner2VideoUrl', 'mainVideoUrl',
            'faviconImage', 'appleTouchImage',
            'overline', 'fullName', 'shortName', 'legalName', 'mainTheme',
        ],
        content: [
            ['row', [
                ['tabs', [
                    ['edit_organization_form_presentation_title', [
                        '!$name!',
                        '$code:readonly!',
                        '$overline',
                        '$street',
                        '$complement',
                        '$zipCode',
                        '$city',
                        '$state',
                        '$country',
                        '$locale',
                    ]],
                    ['edit_organization_form_images_title', [
                        '$logoImage',
                        '$logoAltImage',
                        '$bannerImage',
                        '$faviconImage',
                        '$appleTouchImage',
                        '$banner2Image',
                        '$mainImage',
                        '$backgroundImage',
                        '$twitterShareImage',
                        '$facebookShareImage',
                    ]],
                    ['edit_organization_form_videos_title', [
                        '$backgroundVideoUrl',
                        '$bannerVideoUrl',
                        '$banner2VideoUrl',
                        '$mainVideoUrl',
                    ]],
                ]],
                ['tabs', [
                    ['edit_organization_form_parameters_title', [
                        '$fullName',
                        '$shortName',
                        '$legalName',
                        '$owner',
                        '$tenant',
                    ]],
                    ['edit_organization_form_socials_title', [
                        '$websiteUrl',
                        '$facebookUrl',
                        '$twitterUrl',
                        '$youtubeUrl',
                        '$instagramUrl',
                        '$linkedinUrl',
                        '$pinterestUrl',
                        '$flickrUrl',
                        '$vimeoUrl',
                        '$tumblrUrl',
                        '$tiktokUrl',
                        '$snapchatUrl',
                        '$redditUrl',
                    ]],
                ]],
            ]],
            '$submit',
        ]
    },
    display: {
        tabs: [
            {name: 'infos'},
            {name: 'users'},
        ]
    },
    list: {
        props: {
            search: false,
            searchSwitch: false,
        },
        columns: [
            {id: 'createdAt', 'label': 'column_createdat_label', format: 'date', flex: 0, width: 150},
            {id: 'code', label: 'column_code_label', flex: 0, width: 100} as any,
            {id: 'name', label: 'column_name_label'},
            {id: 'ownerEmail', label: 'column_owner_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;