const t = {
    icon: 'fa-user-tie',
    menus: {
        main: {section: 'settings', priority: 30},
    },
    new: {
        attributes: [
            'email', 'lastName', 'firstName', 'phone', 'tenant', 'role', 'password',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '!$email!', '$lastName!', '$firstName!', '$phone', '$role!', '$password!',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'tenant','email', 'lastName', 'firstName', 'phone','avatarImage',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant:readonly','$code:readonly', '!$email!', '$lastName', '$firstName', '$phone', '$matricule','$avatarImage',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id', 'firstName', 'lastName', 'email','avatarImage',
        ],
    },
    list: {
        props: {
            search: false,
            searchSwitch: false,
        },
        icon: 'fa-users',
        columns: [
            {id: 'email', label: 'column_email_label'},
            {id: 'lastName', label: 'column_lastname_label'},
            {id: 'firstName', label: 'column_firstname_label'},
            {id: 'avatar', label: 'column_firstname_label'},
            {id: 'role', label: 'column_role_label' },
            {id: 'tenantName', label: 'column_tenant_label' },
            {id: 'avatarImage',label:'column_avatarImage_label'}
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;