const t = {
    icon: 'fa-file',
    menus: {
        main: {section: 'customers', priority: 20},
    },
    new: {
        attributes: [
           'customer', 'tenant','name'
        ],
        content: [
            ['row', [
                ['block', [
                    '!$tenant!', '$customer!','$name!'
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'tenant', 'customer', 'name'
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant', '$customer','!$name'
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id', 'customerName','tenantName','name','code'
        ],
    },
    list: {
        props: {
            search: false,
            searchSwitch: false,
        },
        icon: 'fa-toolbox',
        columns: [
            {id: 'tenantName', label: 'column_tenant_label'},
            {id: 'customerName', label: 'column_customer_label'},
            {id: 'code', label: 'column_code_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;