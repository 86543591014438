const t = {
    icon: 'fa-file',
    menus: {
        main: {section: 'employee', priority: 30},
    },
    display: {
        properties: [
            'mooncardId','name','cost','vatCost','vatPercent'
        ],
    },
    list: {
        toolbar:true,
        props: {
            search: false,
            searchSwitch: false,
        },
        icon: 'fa-chart-column',
        columns: [
            {id: 'mooncardId', label: 'column_mooncard_id_label'},
            {id: 'name', label: 'column_name_label'},
            {id: 'cost', label: 'column_cost_label'},
            {id: 'vatCost', label: 'column_vatcost_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;