const t = {
    icon: 'fa-user-tie',
    menus: {
        main: {section: 'employees', priority: 1},
    },
    new: {
        attributes: [
            'email', 'lastName', 'firstName', 'tenant','externalId','mooncardId', 'user'
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant!', '!$email!', '$lastName!', '$firstName!','$externalId','$mooncardId', '$user'
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'email', 'lastName', 'firstName','externalId','mooncardId', 'user'
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant:readonly!', '$code:readonly!', '!$email!', '$lastName', '$firstName','$externalId','$mooncardId','$user'
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        toolbar:true,
        tabs: [
            {name: 'infos'},
            {name: 'eperiods'},
            {name: 'expenses'}
        ],
        properties: [
            'id', 'code', 'firstName', 'lastName', 'email','externalId','mooncardId','statAccountAmount', 'user'
        ],
    },
    list: {
        props: {
            search: false,
            searchSwitch: false,
        },
        icon: 'fa-users',
        columns: [
            {id: 'code', label: 'column_code_label'},
            {id: 'externalId', label: 'column_externalid_label'},
            {id: 'email', label: 'column_email_label'},
            {id: 'lastName', label: 'column_lastname_label'},
            {id: 'firstName', label: 'column_firstname_label'},
            {id: 'role', label: 'column_role_label' },
            {id: 'statAccountAmount', label: 'column_stataccountamount_label' },
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
    types: {
       eperiod: {
                list: {
                    moveable:false,
                    columns:[
                        {id: 'id', label: 'column_id_label'},
                        {id: 'contract', label: 'column_contract_label'},
                        {id: 'projectName', label: 'column_project_label'},
                        {id: 'workedDay', label: 'column_workedday_label'},
                        {id: 'locked', label: 'locked ?'},
                        {id: 'externalId', label: 'column_externalid_label'},
                        {id: 'dateStart', label: 'column_datestart_label',format: (v: any) => v ? new Date(parseInt(v)*1000).toLocaleString() : 'undefined'},
                        {id: 'dateEnd', label: 'column_dateend_label',format: (v: any) => v ? new Date(parseInt(v)*1000).toLocaleString() : 'undefined'},
                    ],
                    globalActions:['add'],
                    actions: ['display','edit','delete']
                }
            },
       expense: {
                list: {
                    moveable:false,
                    columns:[
                        {id: 'id', label: 'column_id_label'},
                        {id: 'tenant', label: 'column_tenant_label'},
                        {id: 'name', label: 'column_name_label'},
                        {id: 'cost', label: 'column_cost_label'},
                        {id: 'vatPercent', label: 'column_vatPercent_label'},
                        {id: 'mooncardId', label: 'column_mooncardId_label'},
                        {id: 'date', label: 'column_date_label', format: (v: any) => v ?  new Date(parseInt(v)*1000).toLocaleString('fr'): 'undefined'},
                    ],
                    actions: ['display','edit','delete']
                }
            }
    }
}; 

export default t;