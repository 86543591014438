import {red} from '@material-ui/core/colors';
import buildMenus from "@genstackio/react-admin-core/lib/utils/buildMenus";
import crudRoutes from "@genstackio/react-admin-core/lib/utils/crudRoutes";
import buildBreadcrumbs from "@genstackio/react-admin-core/lib/utils/buildBreadcrumbs";
import buildUploader from "@genstackio/react-admin-core/lib/utils/buildUploader";
import buildLists from "@genstackio/react-admin-core/lib/utils/buildLists";
import * as types from './types';

export const app = {
    prefix: 'g8w_admin',
    endpoint: process.env.REACT_APP_API_ENDPOINT,
    locales: ['fr-FR'],
    requiredRoles: ['admin'],
};
export const apiOptions = {
    timeout: 15000,
};
export const logos = {
    logo: {url: 'https://mon-espace.gentleman-worker.com/assets/backend/img/logo-GC.png', alt: 'G8W'},
    logoFull: {url: 'https://mon-espace.gentleman-worker.com/assets/backend/img/logo-GC.png', alt: 'G8W'},
};
export const uploader = buildUploader(process.env.REACT_APP_UPLOADS_ENDPOINT);
export const theme = {
    tailwind: {},
    mui: {
        palette: {
            primary: {
                main: '#FBBD2A',
            },
            secondary: {
                main: '#2663E9',
            },
            error: {
                main: red.A400,
            },
            background: {
                default: '#FFFFFF',
            },
        },
    },
    theme: 'theme-flashy',
};
export const ambiance = {
    sectionHeaderCorner: 'rounded-xxsmall',
    menuItemCorner: 'rounded-xsmall',
    menuFoldCorner: 'rounded-xxxsmall',
    buttonCorner: 'rounded-xsmall',
};
export const routes = [
    {path: "/user/forgot-password", screen: 'forgot_password', secured: false},
    {path: '/', exact: false, screen: 'app', routes: [
            {path: '/', screen: 'home', exact: true},
            {path: '/', exact: false, screen: 'crud_app', routes: [
                    ...crudRoutes(types),
                    {path: "/user/profile/edit", screen: 'user/edit_current_user'},
                    {path: "/user/profile", screen: 'user/user_profile'},
                    {screen: 'not_found', exact: false, secured: false},
                ]},
            {screen: 'not_found', exact: false, secured: false},
        ]},
    {screen: 'not_found', exact: false, secured: false},
];
export const lists = buildLists(types);
export const breadcrumbs = buildBreadcrumbs(types, ({t}: any) => ({
    'user-profile': [
        {label: t('screen_home_label'), target: '/'},
        {label: t('screen_user_profile_label'), target: '/user/profile'},
    ],
    default: [
        {label: t('screen_home_label'), target: '/'},
    ],
}));
export const menus = buildMenus(types, {
    main: {
        employees:  {},
        customers: {},
        leads:     {},
        settings:  {},
    },
    user: {
        '-': {
            items: ({t}: {t: Function}) => [
                {label: t('user_menu_profile_label'), target: '/user/profile'}
            ],
        }
    }
});
export * as queries from './queries';
export {default as fields} from './fields';
export {default as icons} from './icons';
export {default as translations} from './translations';
export {default as importers} from './importers';