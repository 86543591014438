const t = {
    icon: 'fa-file',
    menus: {
        main: {section: 'customers', priority: 1},
    },
    new: {
        attributes: [
             'customer','project','tenant','createdAt','updatedAt',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$customer!','$tenant!','$project',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'customer','project','tenant',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$customer!','$tenant!','$project'
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
           'customer','project','tenant','createdAt','updatedAt',
        ],
    },
    list: {
        props: {
            search: false,
            searchSwitch: false,
        },
        icon: 'fa-chart-column',
        columns: [
            {id: 'id', label: 'column_id_label'},
            {id: 'tenantName', label: 'column_tenant_label'},
            {id: 'customerName', label: 'column_customer_label'},
            {id: 'project', label: 'column_project_label'},
            {id: 'dateStart', label: 'column_datestart_label',format: 'date'},
            {id: 'dateEnd', label: 'column_dateend_label',format:'date'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;