const t = {
    icon: 'fa-file',
    menus: {
        main: {section: 'customers', priority: 50},
    },
    new: {
        attributes: [
            'tenant','customer','cutomerName','customerCode','project',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$tenant!', '$customer!', '$project',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'tenant','customer','project','createdAt',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$tenant', '$customer', '$project','$createdAt:readonly!'
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id','tenant', 'customer', 'project','createdAt',
        ],
    },
    list: {
        props: {
            search: false,
            searchSwitch: false,
        },
        icon: 'fa-money-bill-wave',
        columns: [
            {id: 'tenantName', label: 'column_tenant_label'},
            {id: 'tenantCode', label: 'column_tenantcode_label'},
            {id: 'customerCode', label: 'column_customer_label'},
            {id: 'project', label: 'column_project_label'},
            {id: 'createdAt' ,label:'column_createdat_label',format:'date' },
            {id: 'updatedAt' ,label:'column_updatedat_label',format:'date',},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;